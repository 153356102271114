<template>
    <li>
        <a
            v-on:click="handleClick(index)"
            v-bind:class="{ selected: isSelected }"
            >{{ name }}</a
        >
    </li>
</template>

<script>
export default {
    name: 'MenuButton',
    props: {
        name: String,
        index: Number,
        contents: String,
        isSelected: Boolean,
        handleClick: Function,
    },
}
</script>

<style scoped>
li {
    margin-bottom: 8px;
    color: #4b4b4b;
    font-size: 14px;
}
.selected {
    font-weight: bold;
    color: #000;
}
a::before {
    content: '·';
    margin: 0 16px 0 8px;
}
</style>
