<template>
    <NavigationArea />
    <MainContainer />
</template>

<script>
import NavigationArea from './components/NavigationArea.vue'
import MainContainer from './components/MainContainer.vue'

export default {
    name: 'App',
    components: {
        NavigationArea,
        MainContainer,
    },
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
    outline: none;
    cursor: pointer;
}
html,
body,
#app {
    height: 100%;
}
#app {
    font-family: 'Noto Sans KR', 'Roboto', sans-serif;
    transition: all 0.24s cubic-bezier(0.5, 1.8, 0.5, 0.8);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    display: flex;
    flex-direction: row;
}
</style>
