<template>
    <ul id="menuList">
        <DropDown
            v-if="menus"
            :options="menus"
            :selected="menus[menuIdx]"
            v-on:updateOption="UpdateMenuIdxOnDropDown"
        ></DropDown>
        <MenuButton
            v-for="menu in menus"
            :name="menu.name"
            :index="menu.id"
            :contents="menu.contents"
            :key="menu.id"
            :isSelected="menu.id === menuIdx"
            :handleClick="UpdateMenuIdx"
        ></MenuButton>
    </ul>
</template>

<script>
import DropDown from './DropDown.vue'
import MenuButton from './MenuButton.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'MenuList',
    components: {
        DropDown,
        MenuButton,
    },
    data() {
        return {
            arrayOfObjects: [],
            object: {
                name: 'Object Name',
            },
        }
    },
    setup() {
        const store = useStore()
        const menus = computed(() => store.state.menus)
        const menuIdx = computed(() => store.state.menuIdx)
        const UpdateMenuIdxOnDropDown = (payload) =>
            store.commit('UpdateMenuIdx', payload.id)
        const UpdateMenuIdx = (idx) => store.commit('UpdateMenuIdx', idx)

        return { menus, menuIdx, UpdateMenuIdx, UpdateMenuIdxOnDropDown }
    },
}
</script>

<style scoped>
#menuList {
    height: auto;
}
</style>
