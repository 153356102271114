import _Promise from './promise.js'
import { _objectToQueryString, _objectIsNull } from './utils.js'

/**
 * Fetch API를 활용한 data-fetch 기능입니다.
 * 편의성을 위해 json형태로 자동변환되어 반환됩니다.
 * @param {string} method API method
 * @param {string} url API endpoint
 * @param {object} payload Payload에 담을 object
 * @returns {_Promise} 결과값을 _Promise 형태로 반환
 */
const _fetch = (method = 'GET', url, payload) =>
    new _Promise((resolve, reject) => {
        let queryString = {}
        let body = {}

        if (method === 'GET') {
            queryString = _objectToQueryString(payload)
        } else {
            body = payload
        }
        const endpoint = `${url}${String(
            _objectIsNull(queryString) ? '' : `?${queryString}`
        )}`
        const options = {
            method: method,
        }
        !_objectIsNull(body) && (options.body = JSON.stringify(body))

        fetch(endpoint, options)
            .then((response) =>
                response.json().then((response) => resolve(response))
            )
            .catch((reason) => reject(reason))
    })

/**
 * XMLHttpRequest API를 활용한 data-fetch 기능입니다.
 * @param {string} method API method
 * @param {string} url API endpoint
 * @param {object} payload Payload에 담을 object
 * @returns {_Promise} 결과값을 _Promise 형태로 반환
 */
const _XHR = (method = 'GET', url, params = {}) => {
    return new _Promise((resolve, reject) => {
        const request = new XMLHttpRequest()
        request.onreadystatechange = () => {
            if (request.readyState === 4) {
                request.status === 200
                    ? resolve(JSON.parse(request.response))
                    : reject(request.response)
            }
        }
        request.open(method, url)
        request.setRequestHeader('Content-Type', 'application/json')
        request.send(JSON.stringify(params))
    })
}

/**
 * 내장 API와 구현한 _Promise를 활용한 data-fetch 기능입니다.
 * fetch가 사용 가능한 경우, _fetch를 반환하고, 아니면 _XHR을 반환합니다.
 * @param {string} method API method
 * @param {string} url API endpoint
 * @param {object} payload Payload에 담을 object
 * @returns {_Promise} 결과값을 _Promise 형태로 반환
 */
const axios = (method, url, payload) => {
    if ('fetch' in window) {
        return _fetch(method, url, payload)
    } else {
        return _XHR(method, url, payload)
    }
}

export default axios
