<template>
    <main>
        <h1 v-if="menus">
            {{ menus[menuIdx].contents }}
        </h1>
    </main>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
    name: 'MainContainer',
    setup() {
        const store = useStore()
        const menuIdx = computed(() => store.state.menuIdx)
        const menus = computed(() => store.state.menus)

        return { menuIdx, menus }
    },
}
</script>

<style scoped>
main {
    flex: 1;
    display: flex;
    justify-content: center;
}
main > *:first-child {
    margin-top: 24px;
}
</style>
