import { PROMISE_STATUS } from './constants.js'
import { _doTasks } from './utils.js'

/**
 * Promise 기능입니다.
 * then과 catch 기능이 구현되었습니다.
 * @param {(resulve, reject) => _Promise} executor
 */
const _Promise = class {
    constructor(executor) {
        this.status = PROMISE_STATUS.PENDING
        this.result = undefined
        this.thenQueue = new Array()
        this.catchQueue = new Array()
        executor(
            (value) => this.resolve(value),
            (error) => this.reject(error)
        )
    }
    then(onFulfilled) {
        const thenActions = {
            [PROMISE_STATUS.PENDING]: () =>
                new _Promise((executor) => {
                    this.thenQueue.push(() => {
                        return executor(onFulfilled(this.result))
                    })
                }),
            [PROMISE_STATUS.FULFILLED]: () =>
                new _Promise((executor) => executor(onFulfilled(this.result))),
            [PROMISE_STATUS.REJECTED]: () => this,
        }

        const result = thenActions[this.status]()
        return result
    }
    catch(onRejected) {
        const catchActions = {
            [PROMISE_STATUS.PENDING]: () =>
                new _Promise((executor) => {
                    this.catchQueue.push(() =>
                        executor(onRejected(this.result))
                    )
                }),
            [PROMISE_STATUS.FULFILLED]: () => this,
            [PROMISE_STATUS.REJECTED]: () =>
                new _Promise((executor) => executor(onRejected(this.result))),
        }

        const result = catchActions[this.status]()
        return result
    }
    resolve(value) {
        if (this.status !== PROMISE_STATUS.PENDING) {
            return this
        }
        this.status = PROMISE_STATUS.FULFILLED
        this.result = value
        _doTasks(this.thenQueue)
    }
    reject(error) {
        if (this.status !== PROMISE_STATUS.PENDING) {
            return this
        }
        this.status = PROMISE_STATUS.REJECTED
        this.result = error
        _doTasks(this.catchQueue)
    }
}

export default _Promise
