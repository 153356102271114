export const _addToTaskQueue = (func) => setTimeout(func, 0)

export const _doTasks = (tasks) =>
    tasks.forEach((func) => _addToTaskQueue(func))

export const _objectToQueryString = (obj) => {
    let query = ''
    if (typeof obj !== 'object') return query
    for (const [key, value] of Object.entries(obj)) {
        if (query) query += `&`
        query += key
        if (value) query += `=${value}`
    }
    return query
}

export const _objectIsNull = (obj) => {
    return (
        JSON.stringify(obj) === '{}' ||
        obj === null ||
        obj === undefined ||
        obj === ''
    )
}
